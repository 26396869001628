import React from 'react';

import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import seo from 'tools/seo';

import LoginModal from 'components/Login/LoginModal';

export default function Forgot() {
  const history = useHistory();
  const { t } = useTranslation('forgot');

  return (
    <div className="Forgot">
      <Helmet>
        {seo.title(t('meta-title'))}
        {seo.noIndex()}
      </Helmet>
      <LoginModal mode="forgot" onClose={() => history.push(`/`)} noDismiss={false} shouldRedirect />
    </div>
  );
}
